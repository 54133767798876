import React from "react";
import { Row, Col } from "reactstrap";
import './signUpSuccessfull.css';

const SignUpSuccessfull = props => {

    return (
        <div className='bl-back-image'>
            <Row>
                <Col xl="12" lg="12" md="12" sm="12" xm="12" style={{marginTop: '10%'}}>
                    <div>
                        <h1 className="text-center text-congrats">
                            Congratulations!
                        </h1>

                        <div className="text-center pl-2 pr-2" style={{fontSize: '20px', marginTop: '40px'}}>
                            <p>
                                You have successfully registered your account.<br/> 
                                <div style={{fontWeight: "bolder"}}>An activation link has been sent to your email</div> using which you can verify your account.
                            </p>
                        </div>

                        <div className="text-center mt-5">
                            <a href="/authentication/login" className="btn btn-primary btn-lg mr-3">
                                <span className="fas fa-user pr-2"></span>
                                Proceed To Login 
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default SignUpSuccessfull;

