import React, { useState, useEffect, useContext } from 'react';
import {InputGroup,InputGroupAddon,InputGroupText,Row,Col, Button} from 'reactstrap';
import ForgotPassword_step1 from "../../views/ForgotPassword/ForgotPassword_step1";
import RememberMe from "./RememberMe";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../jwt/_services';
import TenantContext from '../../utils/tenantContext';
import GoogleOauth from './googleOauth';
import IubendaCookieConsent from '../../components/iubendaCookieConsent';
import LoaderInner from "../../components/LoaderInner";
import OtpModal from "../../components/otpModal";

const sidebarBackground = {
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const Login = (props) => {
	const {tenant} = useContext(TenantContext);
	const [signUpEnabled, signUpEnabledSET] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [defaultTwoFactorMethod, setDefaultTwoFactorMethod] = useState("");
	const [availableTwoFactorMethods, setAvailableTwoFactorMethods] = useState([]);
	const [user, setUser] = useState("");

	const handleClick = () => {
        var elem = document.getElementById('loginform');
        elem.style.transition = "all 2s ease-in-out";
        elem.style.display = "none";
        document.getElementById('recoverform').style.display = "block";
    };

    const handleToLogin = () => {
        var elem = document.getElementById('recoverform');
        elem.style.transition = "all 2s ease-in-out";
        elem.style.display = "none";
        document.getElementById('loginform').style.display = "block";
    };

    useEffect(() => {
        if (authenticationService.currentUserValue?.value) {
            const { from } = props.location.state || { from: { pathname: "/" } };
            props.history.push(from);
        }
    }, [props.history, props.location.state]);

	
	useEffect(() => {
		if(tenant && tenant.self_signup) {
			signUpEnabledSET(true);
		}
	}, [tenant]);


		return (
			<div className="">
				{/*--------------------------------------------------------------------------------*/}
				{/*Login Cards*/}
				{/*--------------------------------------------------------------------------------*/}
				<div className="auth-wrapper d-flex align-items-center" style={sidebarBackground}>

					<div className="container">
						<div id="loginform">
							<Row className="no-gutters justify-content-center">
								{/* <Col md="6" lg="4" className="bg-primary text-white">
									<div className="p-4">
										<h2 className="display-5">Hi,<br />
										<span className="text-cyan font-bold">let's send some loud music out there</span></h2>
									</div>
								</Col> */}
								<Col md="6" lg="4" className="bg-white">
									<div className="p-4">
										<h3 className="font-medium mb-3">Log In to your account</h3>
										<Formik
												initialValues={{
													username: '',
													password: ''
												}}
												validationSchema={Yup.object().shape({
													username: Yup.string().required('Email is required'),
													password: Yup.string().required('Password is required')
												})}
												onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
													setIsLoading(true);
													setStatus("");
													authenticationService.login(username, password)
													.then(
														user => {
															setUser(user);
															authenticationService.request2FAToken(user).then(
																response => {
																	// console.log(response);
																	setDefaultTwoFactorMethod(response.two_factor_method);
																	setAvailableTwoFactorMethods(response.available_methods);
																	setIsLoading(false);
																	setSubmitting(false);
																	setShowOtpModal(true);
																},
																error => {
																	setIsLoading(false);
																	setSubmitting(false);
																	setAvailableTwoFactorMethods([]);
																	setStatus("Something went wrong! Please refresh page and try again!");
																}
															);
														},
														error => {
															setIsLoading(false);
															setSubmitting(false);
															setAvailableTwoFactorMethods([]);
															setStatus(error);
														}
													);
												}}
												render={({ errors, status, touched, isSubmitting }) => (
													<Form className="mt-3" id="loginform">
														<InputGroup className="mb-3">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ti-email"></i>
																</InputGroupText>
															</InputGroupAddon>

															<Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} />
															<ErrorMessage name="username" component="div" className="invalid-feedback" />
														</InputGroup>
														<InputGroup className="mb-3">
															<InputGroupAddon addonType="prepend">
																<InputGroupText>
																	<i className="ti-lock"></i>
																</InputGroupText>
															</InputGroupAddon>
															<Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
															<ErrorMessage name="password" component="div" className="invalid-feedback" />

														</InputGroup>
														<div className="d-flex no-block align-items-center mb-3">
															<RememberMe />
														</div>
														<Row className="mb-3">
															<Col xs="12">
																<Button size="lg" color="primary" type="submit" block disabled={isSubmitting}>Login</Button>
															</Col>
														</Row>
														{status &&
															<div className={'alert alert-danger'}>{status}</div>
														}
														
														{
															signUpEnabled &&
															<div className="justify-content-center d-flex align-items-center">
																Don't have an account? <a href="/authentication/signup" style={{paddingLeft: 6}} className="forgot text-cyan float-right">Register</a>
															</div>
														}

														<div className="justify-content-center d-flex align-items-center">
															Forgot password? <a href="#recoverform" id="to-recover" style={{paddingLeft: 6}} onClick={handleClick} className="forgot text-cyan float-right">Reset</a>
														</div>
														{/* <hr/>
														<h4 className="text-center text-bold">Or</h4>
														<div className="text-center">
															<GoogleOauth />
														</div> */}
													</Form>
												)}
											/>
									</div>

								</Col>
							</Row>
						</div>
						<div id="recoverform">
							<ForgotPassword_step1 handleToLogin={handleToLogin}/>
						</div>
						<IubendaCookieConsent />
					</div>
				</div>
				<LoaderInner show={isLoading} />
				<OtpModal
					user={user}
					isOpen={showOtpModal}
					setOpen={setShowOtpModal}
					defaultTwoFactorMethod={defaultTwoFactorMethod}
					availableTwoFactorMethods={availableTwoFactorMethods}
				/>
			</div>
		);
};

export default Login;
