import { BehaviorSubject } from "rxjs";
import axios from "axios";
import { setCookie, removeCookie, getCookie } from "../_helpers/cookie";
import { handleResponse, handleLoginResponse, handleSocialAuthResponse, parseJwt } from "../_helpers";

const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

let token = getCookie("token");
let refresh = getCookie("refresh");
let rememberBe = getCookie("rememberBe");
let JWT_decode = token ? parseJwt(token) : null;
let currentUserSubject = new BehaviorSubject(null);


const storeToken = (token) => {
  currentUserSubject.next(token);
  setCookie("token", token);
  localStorage.setItem("currentUser", JSON.stringify(token));
};


const getNewToken = (refreshToken) => {
  return axios({
    method: "POST",
    mode: 'cors',
    url: `${API_URL}refresh-token/`,
    data: {"refresh": refreshToken},
    headers: {
      "Content-Type": "application/json",
      "x-api-key": X_API_KEY,
    }
  }).then(res => {
    const newToken = res.data.access;
    storeToken(newToken);
    return true;
  }).catch(e => {
    logoutNoRefresh();
    currentUserSubject.next(null);
    return false;
  });
};


if (!JWT_decode || Date.now() >= JWT_decode.exp * 1000) {
  if (refresh && rememberBe === "1") {
    getNewToken(refresh);
  } else {
    logoutNoRefresh();
    currentUserSubject.next(null);
  }
} else {
  storeToken(token);
}


export const authenticationService = {
  login,
  request2FAToken,
  validateOTP,
  logout,
  logoutNoRefresh,
  socialAuth,
  currentUserSubject,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject;
  }
};

function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "x-api-key": X_API_KEY,
    },
    body: JSON.stringify({ username, password })
  };

  return fetch(
    `${API_URL}obtain-token/`,
    requestOptions
  )
    .then(handleLoginResponse)
    .then(user => {
      // localStorage.setItem("currentUser", JSON.stringify(user.access));
      // setCookie("token", user.access);
      // setCookie("refresh", user.refresh);
      // currentUserSubject.next(user.access);
      return user;
    });
}

function request2FAToken(user, selected2FAMethod = null) {
  const requestBody = selected2FAMethod !== null
    ? { two_factor_method: selected2FAMethod }
    : {};

  const requestOptions = {
    method: "POST",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${user.access}`,
      "Content-Type": "application/json",
      "x-api-key": X_API_KEY,
    },
    body: JSON.stringify(requestBody)
  };

  return fetch(
    `${API_URL}request-2fa-token/`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error("Error requesting 2FA token:", error);
      return Promise.reject(error);
    });
}

function validateOTP(user, otpCode, selected2FAMethod) {
  const requestOptions = {
    method: "POST",
    mode: 'cors',
    headers: {
      Authorization: `Bearer ${user.access}`,
      "Content-Type": "application/json",
      "x-api-key": X_API_KEY,
    },
    body: JSON.stringify({ otp_code: otpCode, two_factor_method: selected2FAMethod })
  };

  return fetch(
    `${API_URL}validate-2fa-token/`,
    requestOptions
  )
    .then(handleResponse)
    .then(response => {
      console.log("Response: ", response);

      if (response.valid) {
        localStorage.setItem("currentUser", JSON.stringify(user.access));
        setCookie("token", user.access);
        setCookie("refresh", user.refresh);
        currentUserSubject.next(user.access);
        return user;
      } else {
        return Promise.reject("Invalid OTP");
      }
    })
    .catch(error => {
      console.error("Error during OTP validation:", error);
      return Promise.reject(error);
    });
}

function socialAuth(provider, authCode, redirectUri, authType) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": X_API_KEY,
    },
    body: JSON.stringify({ 
      provider: provider, 
      code: authCode,
      redirect_uri: redirectUri,
      auth_type: authType,
    })
  };

  return fetch(
    `${API_URL}social-auth/`,
    requestOptions
  )
    .then(handleSocialAuthResponse)
    .then(user => {
      localStorage.setItem("currentUser", JSON.stringify(user.token));
      setCookie("token", user.token);
      setCookie("refresh", user.refresh);
      currentUserSubject.next(user.token);
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject = new BehaviorSubject(null);
  removeCookie("token");
  removeCookie("refresh");
  window.location.reload();
}

function logoutNoRefresh() {
  localStorage.removeItem("currentUser");
  currentUserSubject = new BehaviorSubject(null);
  removeCookie("token");
  removeCookie("refresh");
}