import React, { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";

const SubUsersTableDropdown = ({
  data,
  setUpdateTitle,
  setUpdateBody,
  setUpdateData,
  setUpdateBtnText,
  setUpdateBtnColor,
  setToggleModal,
  setDataModal,
  setLastAction
}) => {
  const currentRoute = useLocation().pathname;
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  
  const editLink = `${currentRoute}/${data.id}/update/`;

  const activateBtnText = data.is_active ? "Deactivate" : "Activate";
  const activateBtnColor = data.is_active? "danger" : "success";
  const activateAction = data.is_active ? `Account de-activated for user ${data.username}.` : `Account activated for user ${data.username}.`;
  const activateUpdateData = data.is_active? {"is_active": false} : {"is_active": true};
  const activateBodyText = data.is_active? `Are you sure you want to De-Activate Sub-User ${data.username}` : `Are you sure you want to Activate Sub-User ${data.username}?`;

  const enableDistributionBtnText = data.distribution? "Disable": "Enable";
  const enableDistributionBtnColor = data.distribution? "danger" : "success";
  const enableDistributionAction = data.distribution ? `Distribution has been disabled for user ${data.username}.` : `Distribution has been enabled for user ${data.username}.`;
  const enableDistributionUpdateData = data.distribution? {"distribution": true, "release_transfer": true} : {"distribution": false, "release_transfer": false};
  const enableDistributionBodyText = data.distribution? `Are you sure you want to Disable Distribution for Sub-User ${data.username}` : `Are you sure you want to Enable Distribution for Sub-User ${data.username}?`;

  const userContactDetailsPresent = data.street_and_number && data.postal_code && data.city && data.country && data.phone;

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret outline color="primary">
        Action
      </DropdownToggle>

      <DropdownMenu>
        <DropdownItem>
            <Link to={editLink}>Edit</Link>
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setUpdateBtnText(activateBtnText);
            setUpdateBtnColor(activateBtnColor);
            setUpdateData(activateUpdateData);
            setUpdateTitle(enableDistributionBtnText);
            setUpdateBody(activateBodyText);
            setLastAction(activateAction);
            setDataModal(data);
            setToggleModal(true);
          }}
          style={{ cursor: "pointer" }}
        >
          {activateBtnText}
        </DropdownItem>
        <DropdownItem
          id={`sda-${data.id}`}
          onClick={() => {
            setUpdateBtnText(`${enableDistributionBtnText} Distribution`);
            setUpdateBtnColor(enableDistributionBtnColor);
            setUpdateData(enableDistributionUpdateData);
            setUpdateTitle(enableDistributionBtnText);
            setUpdateBody(enableDistributionBodyText);
            setLastAction(enableDistributionAction);
            setDataModal(data);
            setToggleModal(true);
          }}
          disabled={enableDistributionBtnText === "Enable" && !userContactDetailsPresent}
          style={{ cursor: "pointer" }}
        >
          {enableDistributionBtnText} Distribution
        </DropdownItem>
        {
          enableDistributionBtnText === "Enable" && !userContactDetailsPresent &&
          <UncontrolledTooltip placement={"bottom"} target={`sda-${data.id}`}>
            Please update user's contact details in order to enable Distribution.
          </UncontrolledTooltip>
        }
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default SubUsersTableDropdown;
